import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FileDTO } from '../../../../dto/file.dto';
import { FileService } from '../../../../../core/services/file.service';
import { HttpEventType } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { commentFormDTO } from '../../../../dto/discussion.dto';
import { FileSizePipe } from '../../../../../core/pipes/file-size.pipe';
import { SpinnerComponent } from '../../../loaders/spinner/spinner.component';
import { UiStore } from '../../../../../core/store/ui.store';
import { CurrentUserPreferenceStore } from '../../../../../core/store/current-user-preference.store';
import { UserMinimalDTO } from '../../../../../features/organization/users/dto/user.dto';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-discussion-panel-submit',
  standalone: true,
  imports: [FormsModule, FileSizePipe, SpinnerComponent, TranslateModule, NgClass],
  templateUrl: './discussion-panel-submit.component.html',
  styleUrl: './discussion-panel-submit.component.scss',
})
export class DiscussionPanelSubmitComponent implements OnInit{
  @ViewChild('fileInput') fileInput: ElementRef; // Reference to the file input
  @ViewChild('reviewInputBlock') reviewInputBlock: ElementRef;

  commentForm: commentFormDTO;
  onChange: any = () => {};
  currentUser: UserMinimalDTO;

  files: FileDTO[] = [];
  commentText: string;
  isClosed: boolean = false; 
  isAccepted: boolean = false;
  isDismissed: boolean = false;

   //Form
   @Input() isDocumentFieldEnabled: boolean = false;
   @Input() isDiscussionStatusFieldEnable: boolean = false;
   @Input() discussionStatuses: any[];


  @Output() saveCommentRequest = new EventEmitter<commentFormDTO>();

  progress: { [key: string]: number } = {};

  constructor(
    private fileService: FileService, 
    private renderer: Renderer2, 
    public uiStore: UiStore, 
    public currentUserPreferenceStore: CurrentUserPreferenceStore,
  ) {}

  ngOnInit(): void {
    this.currentUser = this.currentUserPreferenceStore.user;
    this.discussionStatuses ? this.discussionStatuses.forEach((status: any) => status.is_selected = false) : [];
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.handleFileInput(input.files);
    }
  }

  handleFileInput(files: FileList) {
    this.upload(files);
  }

  upload(files: FileList) {
    if (!this.files) {
      this.files = [];
    }

    if (!this.progress) {
      this.progress = {};
    }

    Array.from(files).forEach((file) => {
      const fileDTO: FileDTO = {
        file_name: file.name,
        original_name: file.name,
        type: file.type,
        size: file.size,
        is_new_file: true,
      };

      this.files.push(fileDTO);
      this.onChange(this.files);

      this.fileService.uploadFile(file).subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            if (event.total) {
              this.progress[file.name] = Math.round(
                (100 * event.loaded) / event.total
              );
            }
          } else if (event.type === HttpEventType.Response) {
            if (event.body) {
              const { type, size, file_name, original_name } = event.body;
              const index = this.files.findIndex(
                (f) => f.original_name === file.name && f.is_new_file
              );
              if (index !== -1) {
                this.files[index] = {
                  ...this.files[index],
                  type: type,
                  size: size,
                  file_name: file_name,
                  original_name: original_name,
                };
              }
              // Update the progress and patch the form control
              this.progress[file.name] = 100;
              this.onChange(this.files);
            }
          }
        },
        (error) => {
          console.error('File upload error:', error);
          this.files = this.files.filter(
            (f) => f.file_name !== file.name || !f.is_new_file
          );
          this.onChange(this.files);
          this.progress[file.name] = 0;
        }
      );
    });
  }

  onDeleteFile(file: FileDTO) {
    const index = this.files.findIndex((f) => f.file_name === file.file_name);
    if (index !== -1) {
      this.files.splice(index, 1);
      this.onChange(this.files);
    }
  }

 
  onStatusChange(selectedStatus: any) {
    this.isAccepted = this.isDismissed = false;
  
    // Toggle the selected status
    selectedStatus.is_selected = !selectedStatus.is_selected;
  
    if (selectedStatus.is_selected) {
      // Set the corresponding flag based on the type
      this.isAccepted = selectedStatus.type === 'accept';
      this.isDismissed = selectedStatus.type === 'dismiss';
      this.isClosed = selectedStatus.type === 'closed'; 
  
      // Uncheck all other statuses
      this.discussionStatuses.forEach(discussionStatus => {
        if (discussionStatus !== selectedStatus) {
          discussionStatus.is_selected = false;
        }
      });
      
    }
  }
  requestToSaveComment() {
    this.commentForm = {
      comment: this.commentText,
      documents: this.files,
      is_close: this.isClosed,
      is_accepted: this.isAccepted,
      is_dismissed: this.isDismissed,
    };

    this.saveCommentRequest.emit(this.commentForm);

    //clear status array    
    if(this.isAccepted || this.isDismissed){
      this.isDiscussionStatusFieldEnable = false;
    }
   
    //clear
    this.commentText = '';
    this.files = [];
    this.isClosed = false
    this.isAccepted = false
    this.isDismissed = false
    this.commentForm = {
      comment: '',
      documents: [],
      is_close: false,
      is_dismissed: false,
      is_accepted: false
    };
    
    // Clear the file input
    if (this.fileInput) {
      this.fileInput.nativeElement.value = ''; 
    }
    // Set focus on the review input block div
    this.focusReviewInputBlock();
  }

  focusReviewInputBlock() {
    if (this.reviewInputBlock) {
      setTimeout(() => {
        this.renderer.setAttribute(
          this.reviewInputBlock.nativeElement,
          'tabindex',
          '-1'
        );
        this.reviewInputBlock.nativeElement.focus();
      }, 500); // Adjust the delay time (in milliseconds) as needed
    }
  }
}
