<div class="document-info-body-block">
  <div style="padding-bottom: 170px">
    @if(isLoading){
      <app-main-loader></app-main-loader>
    }@else if(status=='draft'){
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex align-items-center justify-content-center text-center" style="height: 350px">
            <div>
              <h5 class="font-lg">{{submitTitle}}</h5>
              <p> {{submitDescription}}</p>
              <br />
              <app-button
                [label]="'Submit'"
                (clickRequest)="submitAction()"
              ></app-button>
            </div>
          </div>
        </div>
      </div>
    }@else if (comments && comments.length == 0) {
    <div class="row">
      <div class="col-md-12">
        <div
          class="d-flex align-items-center justify-content-center text-center"
          style="height: 350px">
          <div>
            <h5 class="font-lg">No Responses Yet</h5>
            <p>
              Start the conversation by sending a message. We’re here to
              <br />help whenever you’re ready.
            </p>
          </div>
        </div>
      </div>
    </div>
    }@else {
    <!-- //show comments -->
      @for (item of comments; track item.id) {
        <app-discussion-panel-comment [item]="item" 
        (updateCommentStatusRequest)="updateCommentStatus($event)">
      </app-discussion-panel-comment>
      }
    }
  </div>
</div>
