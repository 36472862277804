import { Pipe, PipeTransform } from '@angular/core';
import { AppSettingsStore } from '../store/app-settings.store';
import moment from 'moment';

@Pipe({
  name: 'dateTimeFormatRelativeTime',
  standalone: true
})
export class DateTimeFormatRelativeTimePipe implements PipeTransform {

  constructor(private appSettingsStore: AppSettingsStore) {}

  transform(value: any): any {
    if (!value || !this.isValidDate(value)) {
      return '';
    }

    const isDateTime = moment(value, moment.ISO_8601, true).isValid() && value.includes('T');
    const timezone = this.appSettingsStore.timezone || moment.tz.guess();

    const formattedDateTime = moment(value).tz(timezone);
    const now = moment();

    // Calculate the difference in days, weeks, and months
    const diffInDays = now.diff(formattedDateTime, 'days');
    const diffInWeeks = now.diff(formattedDateTime, 'weeks');
    const diffInMonths = now.diff(formattedDateTime, 'months');

    // If the date is within the past week, show relative time in days (e.g., "6 days ago")
    if (diffInDays < 7) {
      return formattedDateTime.fromNow(); // e.g., "6 days ago"
    }

    // If the date is within the past few weeks (less than a month), show weeks ago
    if (diffInWeeks < 4) {
      return diffInWeeks === 1 ? '1 week ago' : `${diffInWeeks} weeks ago`; // e.g., "2 weeks ago"
    }

    // If the date is within the past few months (less than a year), show months ago
    if (diffInMonths < 12) {
      return diffInMonths === 1 ? '1 month ago' : `${diffInMonths} months ago`; // e.g., "3 months ago"
    }

    // Otherwise, return the formatted date based on the app's settings
    const format = isDateTime ? this.appSettingsStore.dateTimeFormat : this.appSettingsStore.dateFormat;
    return formattedDateTime.format(format);
  }

  private isValidDate(value: any): boolean {
    return moment(value, moment.ISO_8601, true).isValid() || moment(value, this.appSettingsStore.dateFormat, true).isValid();
  }

}
