import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NoDataPageComponent } from '../../../pages/no-data-page/no-data-page.component';
import { MainLoaderComponent } from '../../../loaders/main-loader/main-loader.component';
import { ButtonComponent } from '../../../buttons/button/button.component';
import { FormsModule } from '@angular/forms';
import { DiscussionPanelSubmitComponent } from '../discussion-panel-submit/discussion-panel-submit.component';
import { DiscussionPanelCommentComponent } from '../discussion-panel-comment/discussion-panel-comment.component';
import { CommentDTO, CommentStatusFormDTO } from '../../../../dto/discussion.dto';

@Component({
  selector: 'app-discussion-panel-body',
  standalone: true,
  imports: [
    NoDataPageComponent,
    MainLoaderComponent,
    ButtonComponent,
    FormsModule,
    DiscussionPanelSubmitComponent,
    DiscussionPanelCommentComponent,
  ],
  templateUrl: './discussion-panel-body.component.html',
  styleUrl: './discussion-panel-body.component.scss',
})
export class DiscussionPanelBodyComponent {
  @Input() isLoading: boolean = true;
  @Input() status: string;
  @Input() submitTitle: string;
  @Input() submitDescription: string;
  @Input() comments: CommentDTO[];
  @Output() submitRequest = new EventEmitter<void>();
  @Output() updateCommentStatusRequest = new EventEmitter<CommentStatusFormDTO>();


  submitAction() {
    this.submitRequest.emit();
  }

  updateCommentStatus(commentStatusForm: CommentStatusFormDTO) {
    this.updateCommentStatusRequest.emit(commentStatusForm);
  }
}
