import { Component, Input } from '@angular/core';
import { LabelDisplayComponent } from '../../details/label-display/label-display.component';
import { UserMinimalDTO } from '../../../../../features/organization/users/dto/user.dto';
import { DateTimeFormatPipe } from '../../../../../core/pipes/date-time-format.pipe';
import { ColorLabelItemDTO } from '../../../../dto/table.dto';

@Component({
  selector: 'app-discussion-panel-header',
  standalone: true,
  imports: [LabelDisplayComponent, DateTimeFormatPipe],
  templateUrl: './discussion-panel-header.component.html',
  styleUrl: './discussion-panel-header.component.scss',
})
export class DiscussionPanelHeaderComponent {
  @Input() title: string;
  @Input() count: number;
  @Input() user: UserMinimalDTO;
  @Input() date: string;
  @Input() description: string;
  @Input() status: ColorLabelItemDTO;
}
