import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LabelDisplayComponent } from '../../details/label-display/label-display.component';
import { UserImageComponent } from '../../widgets/user-image/user-image.component';
import { DateTimeFormatRelativeTimePipe } from '../../../../../core/pipes/date-time-format-relative-time.pipe';
import { CommentDTO, CommentStatusFormDTO } from '../../../../dto/discussion.dto';

@Component({
  selector: 'app-discussion-panel-comment',
  standalone: true,
  imports: [LabelDisplayComponent, UserImageComponent, DateTimeFormatRelativeTimePipe],
  templateUrl: './discussion-panel-comment.component.html',
  styleUrl: './discussion-panel-comment.component.scss',
})
export class DiscussionPanelCommentComponent {
  @Input() item: CommentDTO;
  commentStatusForm: CommentStatusFormDTO

  @Output() updateCommentStatusRequest = new EventEmitter<CommentStatusFormDTO>();

  resolveRequest() { 
    this.commentStatusForm = {
      comment_id: this.item.id,
      is_resolve: true,
    };

    this.updateCommentStatusRequest.emit(this.commentStatusForm);
    
  }

  reopenRequest() { 
    this.commentStatusForm = {
      comment_id: this.item.id,
      is_resolve: false,
    };
    this.updateCommentStatusRequest.emit(this.commentStatusForm);

  }

}
