<div class="document-head-block">
  <div class="row">
    <div class="col-md-12">
      <h2>{{title}}</h2>
    </div>

      <!-- <div class="col-md-4">
        <p class="font-lg fw-500 mb-4">
          <span class="position-relative me-4">
            <div class="badge badge-danger">{{count}}</div>
            <img src="assets/images/icons/icon-chat.svg" alt="Chat Icon" />
          </span>
           New Replies
        </p>
      </div> -->
  </div>
  <div class="row mt-3">
    @if(user){
      <div class="col-sm-4">
        <app-label-display
            [type]="'user'"
            [label]="'Requested by'"
            [user]="user">
        </app-label-display>
      </div>
    }
   @if(date){
    <div class="col-sm-4">
      <span class="label-text">Requested Date:</span>
      <p class="p-text">{{date | dateTimeFormat}}</p>
    </div>
   }
   @if(status){
      <div class="col-sm-4">
        <app-label-display
            [type]="'status'"
            [label]="'Status'"
            [status]="status">
        </app-label-display>
      </div>
    }
 
    <div class="col-sm-12">
      <app-label-display
      [type]="'para'"
      [label]="'Description'"
      [value]=" description">
    </app-label-display>
    </div>
  </div>
</div>
