<div class="row">
  <div class="col-md-12">
    <div class="review-input-block position-fixed" style="bottom: 20px; width: 37.8%">

      @if(files){
      <div class="review-button-block">
        @for(file of files; track file) {
        <div class="uploaded-file justify-content-between align-items-center mb-2">
          <div class="d-flex">
            <img
              src="assets/images/icons/icon-pdf.svg"
              width="20"
              class="me-2"/>
            {{ file.original_name }}
          </div>
          <small class="ms-2" style="margin-top: 3px">
            {{ file.size | fileSize }}</small>

            <button
              type="button"
              class="btn btn-link text-danger ms-2"
              (click)="onDeleteFile(file)">
              <img src="assets/images/icons/icon-delete.svg" width="20" />
            </button>
        </div>
        }
      </div>
      }

      @if(isDiscussionStatusFieldEnable){
        <div class="review-button-block">
          @for (discussionStatus of discussionStatuses; track $index) {
            <label class="action-button w-auto me-2 review-btn" [ngClass]="discussionStatus.class" for="acceptCheckbox{{ $index }}">
              <input 
                type="checkbox" 
                id="acceptCheckbox{{ $index }}"
                class="d-none" 
                [checked]="discussionStatus.is_selected" 
                (change)="onStatusChange(discussionStatus)"
                />
                @if(discussionStatus.is_selected){
                  <img src="assets/images/icons/icon-green-check-circle.svg" />
                }
                {{discussionStatus.label | translate}}
            </label>
          }
        </div>
      }


      <div class="review-input-textarea mt-3">
        <textarea
          id="commentTextArea"
          placeholder="Write your comment.."
          rows="2"
          class="form-control"
          [(ngModel)]="commentText"
        >
        </textarea>
      </div>
      <div class="review-attachement-block">

      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          @if(isDocumentFieldEnabled){
            <span class="custom-upload">
              <input type="file" id="file" style="display: none;" multiple (change)="onFileChange($event)">
              <label for="file" class="action-button">
                <div>
                  <img src="assets/images/icons/icon-attach-file.svg" class="d-block mx-auto cursor-pointer" alt="upload icon" />
                </div>
              </label>
            </span>
          }
         
       
      </div>

        @if (!uiStore.saving) {
          <button
            type="button"
            class="btn-green-send me-2"
            [disabled]="!commentText"
            (click)="requestToSaveComment()">
            <img src="assets/images/icons/icon-send.svg" />
          </button>
        }@else if (uiStore.saving) {
          <button class="btn btn-primary btn-theme me-2" type="button" disabled>
            <app-spinner></app-spinner>
          </button>
        }
      </div>
      </div>
    </div>
  </div>
</div>
