  <div class="row">
      <div class="col-md-12">
          <div class="d-flex">

              <app-user-image
                [initial]="item.commented_user?.initial ?? ''"
                [color]="item.commented_user?.color ?? ''"
                [type]="'small'">
              </app-user-image>

              <div class="review-right-block w-100 ms-2">
                  <div class="d-flex justify-content-between">
                      <h5 class="mb-0">
                        {{ item.commented_user?.first_name ?? 'Admin' }}
                        {{ item.commented_user?.last_name ?? '' }}
                          @if (item.commented_user?.id == item.requested_by) {
                            <span class="fw-300">Author</span>
                          }
                      </h5>
                      <div class="review-date">{{item.commented_at | dateTimeFormatRelativeTime}}</div>
                  </div>
                  <p>{{item.comment}}</p>

                  @if (item.hasOwnProperty('is_resolved')) {
                    @if(item.is_resolved){
                      <a href="javascript:void(0)" (click)="reopenRequest()" class="a-link">Reopen</a>
                    }@else{
                      <a href="javascript:void(0)" (click)="resolveRequest()" class="a-link">Resolve</a>
                    }
                  }


                  @if(item.documents.length>0){
                    <app-label-display
                        [type]="'documents'"
                        [label]="'Documents'"
                        [maxLength]="40"
                        [documents]="item.documents">
                    </app-label-display>
                  }
                 
              </div>
          </div>
      </div>
  </div>
